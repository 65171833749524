import React from "react";
// lib
import { PulseLoader } from "react-spinners";

const Loading = ({ loading = false, full = false }) => {
  return (
    <div
      className={`my-auto d-flex ${
        full && "vh-100"
      } justify-content-center align-items-center`}
    >
      <PulseLoader
        className={`${
          !full && "position-absolute top-50 start-50 translate-middle"
        } `}
        color="cyan"
        loading={loading}
        size={13}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Loading;
