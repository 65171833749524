import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const Empty = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="w-100">
      <p className="text-warning text-center fw-bold fs-6">Empty Data</p>
      <button
        onClick={() => navigate(`/${location.pathname.split("/")[1]}`)}
        className="position-absolute top-50 start-50 btn btn-danger shadow-lg btn-md text-uppercase fw-bolder font-monospace rounded-2"
        style={{ transform: "translate(-50%,-50%)" }}
      >
        Return Back
      </button>
    </div>
  );
};
